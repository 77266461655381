
<div id="content">
    <h1>Tutorial</h1>
    <ul>
        <li><span>Scrolling:</span> Zoom in and out using your mouse-wheel</li>
        <li><span>Dragging:</span> Click and drag to move around</li>
        <li><span>Alt + Scroll:</span> Increases/Decreses Iterations</li>
        <li><span>Shift + Scroll:</span> Changes the current color mode</li>
        <li><span>Ctrl + Scroll:</span> Changes the current modifier mode</li>
        <li><span>Right-click:</span> Saves/Downloads image</li>
        <li><span>Press <i>j</i>:</span> Swaps between mandelbrot- and julia-view</li>
        <li><span>Press <i>m</i>:</span> Mutes the music</li>
        <li><span>Press <i>t</i>:</span> Opens/Closes the tutorial</li>
        <li><span>Press <i>Space</i>:</span> Shows/Hides the HUD</li>
    </ul>
    <span id="proc">Click anywhere to proceed</span>
</div>